import { Component, OnInit } from '@angular/core';
////import {BsModalService,BsModalRef} from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs';
import { MatDialog,MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  public onClose: Subject<boolean>;
  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  yesfn(){
   this.onClose.next(true);
   /// this._bsModalRef.hide();
  } 
  nofn(){
    this.onClose.next(false);
    //this._bsModalRef.hide();
  } 
  action(value: boolean) {
   // this._bsModalRef.hide();
    //this.subject.next(value);
    //this.subject.complete();
  }
  close(): void {
    this.dialogRef.close();
  }
}
