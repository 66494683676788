import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UserState {
    data: {},
    isLoggedIn: boolean,
    plan: string
}

export function createInitialState(): UserState {
    return {
        data: null,
        isLoggedIn: false,
        plan: null
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'User' })
export class UserStore extends Store<UserState>{
    constructor() {
        super(createInitialState());
    }
}