import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() total = 0;
  @Input() limit = 10;
  @Output() change = new EventEmitter<number>();

  totalPages = 0;
  pages = [];

  activePage = 0;
  totalVisiblePages = 5;

  constructor() { }

  ngOnInit() {
    this.totalPages = Math.ceil(this.total / this.limit);
    this.gotoFirst(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.total) {
      this.totalPages = Math.ceil(changes.total.currentValue / this.limit);
      this.gotoFirst(false);
    }
  }

  goto(page) {

    const position = this.pages.indexOf(page);
    if (this.totalVisiblePages - position < this.totalVisiblePages / 2) {
      for (let i = 1; i < Math.ceil(this.totalVisiblePages / position) && this.pages[this.pages.length - 1] < this.totalPages - 1; i++) {
        this.pages.push(this.pages[this.pages.length - 1] + 1);
        this.pages.splice(0, 1);
      }
    } else if (this.totalVisiblePages - position > this.totalVisiblePages / 2) {
      for (let i = 1; i < Math.ceil(this.totalVisiblePages / 2) - position && this.pages[0] > 0; i++) {
        this.pages.splice(0, -1, this.pages[0] - 1);
        this.pages.splice(this.pages.length - 1, 1);
      }
    }

    this.activePage = page;
    this.change.emit(this.activePage);
  }
  gotoFirst(doEmit = true) {
    this.pages = [];
    for (let i = 0; i < this.totalVisiblePages && i < this.totalVisiblePages && this.pages.length < this.totalPages; i++) {
      this.pages.push(i);
    }

    this.activePage = 0;
    if (doEmit) {
      this.change.emit(this.activePage);
    }
  }

  gotoLast() {
    this.pages = [];
    for (let i = this.totalPages - 1; this.pages.length < this.totalVisiblePages && i > -1; i--) {
      this.pages.splice(0, -1, i);
    }

    this.activePage = this.totalPages - 1;
    this.change.emit(this.activePage);
  }

  gotoBack() {
    const nxtpage = this.activePage - 1;
    if (nxtpage < 0) {
      return;
    }
    this.goto(nxtpage);
  }

  gotoNext() {
    const nxtpage = this.activePage + 1;
    if (nxtpage > this.totalPages - 1) {
      return;
    }
    this.goto(nxtpage);
  }
}
