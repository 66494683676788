<div class="common-form-input" #panelDOM>
    <div class="label" *ngIf="label">{{ label }}</div>
    <div class="phone-wrap" *ngIf="type == 'phone'">
        <select [(ngModel)]="phoneCode" (change)="emitValue()">
            <option *ngFor="let item of phoneCodeList">{{ item?.dial_code }}</option>
        </select>
        <input type="text" [(ngModel)]="phoneInput" (keyup)="emitValue()" maxlength="10">
    </div>
    <div (click)="togglePicker()">
        <ng-content select="input"></ng-content>
    </div>
    <div class="suffix" (click)="togglePicker()">
        <ng-content select="[suffix]"></ng-content>
        <img src="/assets/icons/calendar.svg" *ngIf="type == 'date'">
    </div>
    <div class="date-wrap" *ngIf="showDatePicker">
        <app-date-picker (change)="setDate($event)" [disableInit]="true" [disableFutureDates]="disableFutureDates">
        </app-date-picker>
    </div>
    <div class="field-error" *ngIf="error && error.errors && error.touched">{{ getError }}</div>
</div>