import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HeadingComponent } from './heading/heading.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { ButtonComponent } from './button/button.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { CardComponent } from './card/card.component';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { AlertComponent } from './alert/alert.component';
import { FormCheckboxComponent } from './forms/form-checkbox/form-checkbox.component';
import { FormSelectComponent } from './forms/form-select/form-select.component';
import { ModalComponent } from './modal/modal.component';


@NgModule({
  declarations: [HeaderComponent, HeadingComponent, FormInputComponent, ButtonComponent, PageContainerComponent, CardComponent, CalendarComponent, PaginatorComponent, DropdownComponent, TimePickerComponent, DatePickerComponent, AlertComponent, FormCheckboxComponent, FormSelectComponent, ModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    HeadingComponent,
    FormInputComponent,
    ButtonComponent,
    PageContainerComponent,
    CardComponent,
    PaginatorComponent,
    DropdownComponent,
    TimePickerComponent,
    DatePickerComponent,
    AlertComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    ModalComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CommonComponentsModule { }
