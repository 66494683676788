<div class="login-container">
    <div class="col s-100 panel-left">
        <img class="bg" src="assets/illustrations/forgot-banner.png">
        <div class="brand">
            <img src="assets/brand-logo-light.png">
        </div>
    </div>
    <div class="col s-100 panel-right">
        <div class="form-wrapper animate fade-in">
            <div class="back" [routerLink]="'../'"><img src="assets/icons/arrow-left.svg"> Back</div>
            <app-heading [title]="'Forgot Password'"></app-heading>
            <form class="form" (submit)="recover($event)" [formGroup]="recoverForm">
                <app-form-input [label]="'Email'" [error]="recoverForm.controls.email">
                    <input type="email" formControlName="email">
                </app-form-input>
                <div class="btn">
                    <app-button>Submit</app-button>
                </div>
            </form>
        </div>
    </div>
</div>