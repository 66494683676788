import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() title;
  @Input() size = 'xl';
  @Input() spacer = true;
  @Input() fillHeight = false;

  constructor() { }

  ngOnInit(): void {
  }

}
