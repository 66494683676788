import { Component, OnInit, Input, ViewChild, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @ViewChild('panelDOM') panelDOM: ElementRef;

  @Input() trigger;
  showMenu = false;

  constructor() { }

  ngOnInit(): void {
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.showMenu && !this.panelDOM.nativeElement.contains(event.target)) {
      this.toggleMenu();
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

}
