import { Injectable } from "@angular/core";
import { Query } from '@datorama/akita';
import { UserState, UserStore } from './user.store';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Auth } from 'aws-amplify';
import { switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState>{
    readonly tokenName = 'qt_token';
    UserName="abc";
    Password="test";
    constructor(protected store: UserStore,
        private cookie: CookieService,
        private router: Router) {
        super(store);
    }

    user() {
        return this.select(user => {
            return user.data
        });
    }

    getToken() {
        return this.cookie.get(this.tokenName);
    }

    setToken(token) {
        return this.cookie.put(this.tokenName, token);
    }

    getUserName() {
        console.log(this.cookie);
        return this.UserName
       // return this.cookie.get(this.UserName);
    }

    setUserName(username) {
        this.UserName=username;
       // return this.cookie.put(this.UserName, username);
    }
    getPassword() {
        return this.Password
        //return this.cookie.get(this.Password);
    }

    setPassword(password) {
        this.Password=password
      //  return this.cookie.put(this.Password, password);
    }
    alreadyLogged() {
        return this.select(user => {
            return user.data
        });
    }

    currentPlan() {
        return this.select(user => {
            return user.plan;
        });
    }

    setCurrentPlan(planId) {
        this.store.update(state => {
            return {
                ...state,
                plan: planId
            }
        });
    }

    currentSession() {
        const ob = new Observable(r => {
            r.next();
        });

        return ob.pipe(
            take(1),
            switchMap(res => {
                return Auth.currentAuthenticatedUser();
            }),
            switchMap((res: any) => {
                if (res) {
                    return of(res)
                } else {
                    this.logout();
                    throwError(false)
                }
            })
        );
    }

    validateUser() {
        const token = this.getToken();
    }

    setUser(data) {
        this.store.update(state => {
            return {
                isLoggedIn: true,
                data: data
            }
        });
    }

    setUserImg(img) {
        this.store.update(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    profileImg: img
                }
            }
        });
    }

    removeUser() {
        this.cookie.remove(this.tokenName);

        this.store.update(state => {
            return {
                isLoggedIn: false,
                data: null
            }
        })
    }

    logout() {
        this.removeUser();
        this.router.navigateByUrl('/');
    }
}