import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements OnInit {

  @Input() value = false;
  @Input() label;
  @Input() color = 'secondary';
  @Output() change = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  toggler() {
    this.value = !this.value;
    this.change.emit(this.value)
  }

}
