import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() disableInit = false;
  @Output() change = new EventEmitter<any>();
  setTimeForm: FormGroup;
  constructor() {
    const date = new Date();
    this.setTimeForm = new FormGroup({
      timeHr: new FormControl(date.getHours() > 12 ? date.getHours() - 12 : date.getHours()),
      timeMin: new FormControl(date.getMinutes() <15?0:date.getMinutes()<30?15:date.getMinutes() <45?30:date.getMinutes()<60?45:0),
      isPm: new FormControl(false),
      timeZone: new FormControl(date.getHours() > 12 ? 12 : 0),
    })
  }

  ngOnInit(): void {
    this.close();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  hourUp() {
    const data = this.setTimeForm.value;
    if (data.timeHr >= 12) {
      return
    }

    let newHr = data.timeHr + 1;
    this.setTimeForm.controls.timeHr.patchValue(newHr);
    this.close();
  }

  hourDown() {
    const data = this.setTimeForm.value;
    if (data.timeHr <= 0) {
      return
    }

    let newHr = data.timeHr - 1;
    this.setTimeForm.controls.timeHr.patchValue(newHr);
    this.close();
  }

  minUp() {
    const data = this.setTimeForm.value;
    if (data.timeMin >= 45) {
       let newHr = 45;
       this.setTimeForm.controls.timeMin.patchValue(newHr);
       console.log(this.setTimeForm.controls.timeMin.patchValue(newHr))
      return
    }
    else{
      let newHr = data.timeMin + 15;
     this.setTimeForm.controls.timeMin.patchValue(newHr);
    }
    
    this.close();
  }

  minDown() {
    const data = this.setTimeForm.value;
    if (data.timeMin <= 0) {
      let newHr =0;
      this.setTimeForm.controls.timeMin.patchValue(newHr);
      return
    }
    else{
      let newHr = data.timeMin - 15;
     this.setTimeForm.controls.timeMin.patchValue(newHr);
    }
    
    this.close();
  }

  setZone(val) {
    this.setTimeForm.controls.timeZone.patchValue(val);
    this.close();
  }

  close() {
    let hour = this.setTimeForm.value.timeHr;
    if (hour < 12) {
      hour += this.setTimeForm.value.timeZone;
    } else if (this.setTimeForm.value.timeZone < 12) {
      hour = 0;
    }
    this.change.emit(hour + ':' + this.setTimeForm.value.timeMin + ':00');
  }
}
