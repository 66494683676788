<div class="user-home-container" id="main-view" #viewDOM>
    <div class="sidebar-warapper">
        <div class="sidebar">
            <div class="brand-name">
                <img class="large" src="/assets/brand-logo.png">
                <img class="small" src="/assets/brand-logo_mini.png">
            </div>
            <div class="nav-list">
                <div class="item spacer"></div>
                <div class="item" *ngFor="let item of navList" [class.active]="item.route === activeSection">
                    <div class="item-main" [routerLink]="'/home/'+item.route"
                        [class.active]="item.route === activePage || (item.route === activeSection && !item.children)">
                        <img [src]="'assets/icons/nav-bar/'+item.icon" class="def">
                        <img [src]="'assets/icons/nav-bar/'+item.iconSelected" class="focus">
                        <span class="label">{{ item.name }}</span>
                    </div>
                    <div class="sub-menu bewel" *ngIf="item.children">
                        <ng-container *ngFor="let sub of item.children">
                            <div class="item-sub bewel" *ngIf="availableFeatures[sub.available] != false && sub.name=='Load Sprint Report' && memberShipPlan=='Advanced'"
                                [routerLink]="'/home/'+item.route+'/'+sub.route"
                                [class.active]="sub.route === activePage">
                                 <span class="label" >{{ sub.name }}</span>
                            </div>
                            <div class="item-sub bewel" [routerLink]="'/home/'+item.route+'/'+sub.route"
                            [class.active]="sub.route === activePage" *ngIf="sub.name !='Load Sprint Report' && availableFeatures[sub.available] != false">
                                <span class="label" >{{ sub.name }}</span>
                            </div>
                        </ng-container>
                        <div class="bewel"></div>
                    </div>
                    <ng-container *ngIf="!item.children">
                        <div class="bewel"></div>
                    </ng-container>
                </div>
                <div class="item spacer"></div>
            </div>
        </div>
    </div>
    <div class="body-container" *ngIf="user">
        <div class="view-wrapper">
            <div class="user-main-panel" id="menuHeaderpanel">
                <div class="menu-trigger" (click)="toggleSidePanel()"><img src="assets/icons/open-menu.svg"></div>
                <div class="user-actions">
                    <div class="notifications">
                        <img src="assets/icons/bell.svg" (click)="openNav()">
                    </div>
                    
                    <div class="user-profile" [class.active]="showUserMenu">
                        <div class="dropdown-trigger" (click)="userMenuToggler()">
                            <div class="thumb">
                                <img  [src]="thumbnail" style="width: 40px;height: 40px;border-radius: 50%;">
                            </div>
                            <div class="label">{{user?.name}} {{user?.middle_name}}</div>
                        </div>
                        <div class="user-nav-list" *ngIf="showUserMenu">
                            <div (click)="navTo('/home/settings')">Profile</div>
                            <div (click)="navTo('/home/settings/orders')">Order History</div>
                            <div (click)="navTo('/home/settings/password')">Change Password</div>
                            <div (click)="navTo('/home/settings/Contactus')">Contact Us</div>
                            <div (click)="navTo('/home/settings/Paymentdetails')">Payment Details</div>
                            <div (click)="navTo('/home/settings/CountryDialing')" *ngIf="availableFeatures.LoadTesting||availableFeatures.ScheduledTesting">InCountry Dialling</div>
                            <div (click)="navTo('/home/settings/WhatsAppProfile')" *ngIf="availableFeatures.LoadTesting||availableFeatures.ScheduledTesting">WhatsApp Profile</div>
                            <div class="red" (click)="logout()">Logout</div>
                        </div>
                    </div>

                </div>
            </div>
            
            <div id="allMainPage" *ngIf="settingWidth" style="width: 80%;">
                 <router-outlet></router-outlet>
            </div>
            <div id="allMainPage" *ngIf="!settingWidth" style="width: 100%;">
                <router-outlet></router-outlet>
           </div>
            <div id="mySidenav" class="Notificationnav">
                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
                <iframe src="https://quicktest.ai/getstarted/" name="targetframe" allowTransparency="true" scrolling="yes" frameborder="0"  height="100%">
                  </iframe>>
              </div>
        </div>
        
    </div>
</div>