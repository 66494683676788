<!-- <script src="https://sdk-cdn.mypurecloud.com/javascript/latest/purecloud-platform-client-v2.min.js"></script>
    <script src="https://sdk-cdn.mypurecloud.com/client-apps/1.3.0/purecloud-client-app-sdk.js"></script>
    
    <script src="https://code.jquery.com/jquery-3.3.1.min.js"></script> -->

<div class="login-container">
    <div class="col s-100 panel-left">
        <div class="banner">
            <img class="bg" src="assets/illustrations/signup-banner.png">
        </div>
        <div class="jumbotron">
            <div class="box-wrapper">
                <div class="box">
                    <div class="heading">Benefits of QuickTest</div>
                    <div class="s-line"></div>
                    <div class="list">
                        <div class="item"><img src="/assets/icons/check-round.svg">Review data on Dashboard</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">Perform Load Testing</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">Scheduled Test Performance</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">Automatic Testing</div>
                        <div class="item"><img src="/assets/icons/check-round.svg">IVR Testing</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="brand">
            <img src="assets/brand-logo-light.png">
        </div>
    </div>
    <div class="col s-100 panel-right">
        <div class="form-wrapper animate fade-in">
            <app-heading [title]="'Sign In'"></app-heading>
            <form class="form" (submit)="signIn()" [formGroup]="loginForm" (keydown.enter)="onEnterKeyDown()">
                <app-alert *ngIf="showError" [type]="'danger'">
                    <div content>{{showError}}</div>
                </app-alert>
                <app-alert *ngIf="showSuccess">
                    <div content>You will be redirected shortly...</div>
                </app-alert>
                <app-form-input [label]="'Email'" [error]="loginForm.controls.email">
                    <input type="email" formControlName="email" autocomplete="off">
                </app-form-input>
                <app-form-input [label]="'Password'" [error]="loginForm.controls.password">
                    <input type="password" formControlName="password" autocomplete="off">
                </app-form-input>
                <div class="forgot-user">
                    <div>
                        <!-- <app-form-checkbox [value]="loginForm.value.remember" (change)="setRemember($event)"
                            [label]="'Remember Me'">
                        </app-form-checkbox> -->
                    </div>
                    <a routerLink="/recover">Forgot Password?</a>
                </div>
                <div class="btn">
                    <app-button>Sign In</app-button>
                </div>
            </form>

            <div class="login-link">
                Don't have an account? <a routerLink="/signup">Sign Up</a>
            </div>
        </div>
    </div>
</div>
<!-- <script src="../purecloud/scripts/main.js"></script> -->