import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import {map,subscribeOn} from 'rxjs/operators'

import {ConfirmationComponent} from '../confirmation/confirmation.component'
import { promise } from 'selenium-webdriver';
import { MatDialog } from '@angular/material/dialog';
export interface ComponentCanDeactivate {
  canDeactivate(): boolean ;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuardGuard implements CanDeactivate<ComponentCanDeactivate> {


  constructor(private dialog: MatDialog) {};
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>| boolean | UrlTree {
  if(!component.canDeactivate()){
    const dialogRef=this.dialog.open(ConfirmationComponent);
    return dialogRef.afterClosed();
    // this.modalRef = this.modalService.show(ConfirmationComponent);
    // console.log(this.modalRef)  
    //  var item=false;
    // this.modalRef.content.onClose.subscribe(result => {
    //   console.log('results', result);
    //   item=result;
    //   return item;
    //  })
    //  return item;
    //return false
  }
  return of(true);
   //return  component.canDeactivate()?true : this.openConfirmDialog();
    // if there are no pending changes, just allow deactivation; else confirm first
    // return component.canDeactivate() ?
    //   true :
    //   // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    //   // when navigating away from your angular app, the browser will show a generic warning message
    //   // see http://stackoverflow.com/a/42207299/7307355
      
    //   window.confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }
  // openConfirmDialog() {
  //   this.modalRef = this.modalService.show(ConfirmationComponent);
  //   console.log(this.modalRef)  
  //   //return true;
  //   var item=false;
  //   this.modalRef.content.subject.subscribe(result => {
  //     return result
  //   })  
  //   //return item;
  // }
}