import { Injectable,Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportsdetailsService {

  _IsSprintDetails:any;
  _fromDate:any;
  _toDate:any;
  _result:any;
  _loadFromDate:any;
  _loadToDate:any;
  _EmailLink:any;
  _highfromDate:any;
  _hightoDate:any;
  constructor() { }

  
  get EmailLink(): any{
    return this._EmailLink;
  }

  @Input() set EmailLink(val: any){
    this._EmailLink = val;
    console.log(this._EmailLink);
  }

  
  get IsSprintDetails(): any{
    return this._IsSprintDetails;
  }

  @Input() set IsSprintDetails(val: any){
    this._IsSprintDetails = val;
    console.log(this._IsSprintDetails);
  }


  get fromDate(): any{
    return this._fromDate;
  }

  @Input() set fromDate(val: any){
    this._fromDate = val;
    console.log(this._fromDate);
  }

  
  get toDate(): any{
    return this._toDate;
  }

  @Input() set toDate(val: any){
    this._toDate = val;
    console.log(this._toDate);
  }
  
  get result(): any{
    return this._result;
  }

  @Input() set result(val: any){
    this._result = val;
    console.log(this._result);
  }

  get loadFromDate(): any{
    return this._loadFromDate;
  }

  @Input() set loadFromDate(val: any){
    this._loadFromDate = val;
    console.log(this._loadFromDate);
  }

  
  get loadToDate(): any{
    return this._loadToDate;
  }

  @Input() set loadToDate(val: any){
    this._loadToDate = val;
    console.log(this._loadToDate);
  }

  get highfromDate(): any{
    return this._highfromDate;
  }

  @Input() set highfromDate(val: any){
    this._highfromDate = val;
    console.log(this._highfromDate);
  }

  get hightoDate(): any{
    return this._hightoDate;
  }

  @Input() set hightoDate(val: any){
    this._hightoDate = val;
    console.log(this._hightoDate);
  }
}
