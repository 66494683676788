import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() open = false;
  @Input() body = '';
  @Input() type = "secondary";
  @Input() primaryBtn = 'Okay';
  @Input() secondaryBtn = 'Cancel';
  @Input() placeholder;
  @Output() close = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onClose(val = null) {
    this.open = false;
    this.close.emit(val);
  }
}
