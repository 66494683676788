<div class="common-time-picker">
    <div class="set-timer">
        <div class="section">
            <button class="trigger" (click)="hourUp()"><img src="assets/icons/chevron-up.svg"></button>
            <div class="label">{{ setTimeForm.value.timeHr }}</div>
            <button class="trigger" (click)="hourDown()"><img src="assets/icons/chevron-down.svg"></button>
        </div>
        <div>:</div>
        <div class="section">
            <button class="trigger" (click)="minUp()"><img src="assets/icons/chevron-up.svg"></button>
            <div class="label">{{ setTimeForm.value.timeMin }}</div>
            <button class="trigger" (click)="minDown()"><img src="assets/icons/chevron-down.svg"></button>
        </div>
    </div>

    <div class="set-zone">
        <div class="section" [class.active]="setTimeForm.value.timeZone === 0" (click)="setZone(0)">AM</div>
        <div class="section" [class.active]="setTimeForm.value.timeZone === 12" (click)="setZone(12)">PM</div>
    </div>
</div>